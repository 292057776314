import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img {...{
      "src": "https://crossfittheville.files.wordpress.com/2011/05/wpid-2011-05-21_09-19-07_419.jpg?w=615",
      "alt": "image"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      